import axios from "axios";

export const API = axios.create({
	baseURL: 'https://api.blackbrickproperty.com',
	withCredentials: false,
	headers: {
		Accept: 'application/json',
	},
});

// GET JOURNAL ALL ARTICLES
export const getArticlesFn = async (topic, page = 1) => {
  const response = await API.get(`/api/journal?topics=${topic}&page=${page}`);
  return response.data;
};


export const getBlogsFn = async (topic = "", page = 1, limit = 10) => {
  const response = await API.get(`/api/blog?topics=${topic}&page=${page}&limit=${limit}`);
  return response.data;
};

// GET BLOG ARTICLES TOPICS
export const getBlogTopicsFn = async () => {
  const response = await API.get(`/api/blogs/topics`);
  return response.data;
};

// GET SINGLE BLOG ARTICLE
export const getBlogFn = async (id) => {
  const response = await API.get(`/api/blog/blog/${id}`);
  return response.data;
};

// GET JOURNAL ARTICLES TOPICS
export const getArticlesTopicsFn = async (data) => {
  const response = await API.get(`/api/journal/topics`);
  return response.data;
};

// GET SINGLE ARTICLE
export const getArticleFn = async (id) => {
  const response = await API.get(`/api/journal/${id}`);
  return response.data;
};

// GET TEAM LISTING
export const getTeamFn = async (isPO= false) => {
  const response = await API.get(`/api/team?isPO=${isPO}`);
  return response.data;
};

// GET PORTFOLIO LISTING
export const getPortfolioFn = async () => {
  const response = await API.get(`/api/portfolio`);
  return response.data;
};

// GET PORTFOLIO DETAILS
export const getPortfolioDetailsFn = async (id) => {
  const response = await API.get(`/api/portfolio/${id}`);
  return response.data;
};

// GET RENTALS PROPERTIES
export const getRentalsPropertiesFn = async ({
  collection = "",
  location = "",
  minBedrooms = "",
  maxBedrooms = "",
  minPrice = "",
  maxPrice = "",
  AgentID ="",
  pageParam = 1,
  category = ""

}) => {
  const response = await API.get(`/api/properties/rentals?collection=${collection}&location=${location}&minBedrooms=${minBedrooms}&maxBedrooms=${maxBedrooms}&minPrice=${minPrice}&maxPrice=${maxPrice}&pageIndex=${pageParam}&AgentID=${AgentID}&category=${category}`);
  return response.data;
};

// GET DETAILS RENTAL PROPERTY
export const getRentalPropertyDetailsFn = async (id) => {
  const response = await API.get(`/api/properties/rentals/property/${id}`);
  return response.data;
};

// GET FEATURED RENTALS PROPERTIES
export const getRentalsFeaturedPropertiesFn = async (id) => {
  const response = await API.get(`/api/properties/rentals/featured`);
  return response.data;
};

// GET ALL RENTALS COMMUNITIES
export const getRentalsCommunitiesFn = async (id) => {
  const response = await API.get(`/api/properties/rentals/communities`);
  return response.data;
};
export const getAllRentalsAgentsFn = async () =>{
  const response = await API.get(`/api/properties/rentals/agents`);
  return response.data;
}
export const getAllSalesAgentsFn = async () =>{
  const response = await API.get(`/api/properties/sales/agents`);
  return response.data;
}
// GET SALES PROPERTIES
export const getSalesPropertiesFn = async ({
  collection = "",
  location = "",
  minBedrooms = "",
  maxBedrooms = "",
  AgentID = "",
  minPrice = "",
  maxPrice = "",
  pageParam = 1,
  category = ""
}) => {
  const response = await API.get(`/api/properties/sales?collection=${collection}&location=${location}&minBedrooms=${minBedrooms}&maxBedrooms=${maxBedrooms}&minPrice=${minPrice}&maxPrice=${maxPrice}&pageIndex=${pageParam}&AgentID=${AgentID}&category=${category}`);
  return response.data;
};


// GET SALES PROPERTIES
export const getLandingPagePropertiesFn = async (
  referenceIds
) => {

  const queryParam = referenceIds.join(',');  // Join the array into a comma-separated string

  const response = await API.get(`/api/properties/landing/page?referenceIds=${queryParam}`);
  return response.data;
};

// GET DETAILS SALE PROPERTY
export const getSalePropertyDetailsFn = async (id) => {
  const response = await API.get(`/api/properties/sales/property/${id}`);
  return response.data;
};



// GET FEATURED SALES PROPERTIES
export const getSalesFeaturedPropertiesFn = async (id) => {
  const response = await API.get(`/api/properties/sales/featured`);
  return response.data;
};



// GET ALL SALES COMMUNITIES
export const getSalesCommunitiesFn = async (id) => {
  const response = await API.get(`/api/properties/sales/communities`);
  return response.data;
};


// GET PRIVATE PROPERTIES
export const getPrivatePropertiesFn = async ({
  collection = "",
  location = "",
  minBedrooms = "",
  maxBedrooms = "",
  minPrice = "",
  maxPrice = "",
  pageParam = 1
}) => {
  const response = await API.get(`/api/properties/private?collection=${collection}&location=${location}&minBedrooms=${minBedrooms}&maxBedrooms=${maxBedrooms}&minPrice=${minPrice}&maxPrice=${maxPrice}&pageIndex=${pageParam}`);
  return response.data;
};

// GET DETAILS SALE PROPERTY
export const getPrivatePropertyDetailsFn = async (id) => {
  const response = await API.get(`/api/properties/private/property/${id}`);
  return response.data;
};
// GET FEATURED SALES PROPERTIES
export const getPrivateFeaturedPropertiesFn = async (id) => {
  const response = await API.get(`/api/properties/private/featured`);
  return response.data;
};

// GET ALL SALES COMMUNITIES
export const getPrivateCommunitiesFn = async (id) => {
  const response = await API.get(`/api/properties/private/communities`);
  return response.data;
};

// GET SLIDES LISTING
export const getSlidesFn = async () => {
  const response = await API.get(`/api/slides`);
  return response.data;
};

// GET TESTIMONIES LISTING
export const getTestimoniesFn = async () => {
  const response = await API.get(`/api/testimonies`);
  return response.data;
};
// GET FLYER IMAGE
export const getFlyerImage = async (refNo, propertyType, flyerType,date ="", startTime= "", endTime ="",startDay = "", endDay = "") => {
  const response = await API.get(`/api/util/flyer/${refNo}?propertyType=${propertyType}&flyerType=${flyerType}&date=${date}&startTime=${startTime}&endTime=${endTime}&startDay=${startDay}&endDay=${endDay}`,{ responseType: 'blob'});
  return response.data;
};

// GET WEBSITE SETTINGS
export const getSettingsFn = async () => {
  const response = await API.get(`/api/websitesettings`);
  return response.data;
};

export const postClickEntity = async (RefNo, PropertyType, clickEntityName) => {
  try {
    const response = await API.post(
      `/api/util/click`,
      {
        RefNo,
        PropertyType,
        clickEntityName
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error calling POST API:", error);
    throw error; // Re-throwing the error for error handling at a higher level
  }
};


export const postAccessRequest = async (name, email, phoneNumber) => {
  try {
    const response = await API.post(
      `/api/request/access`,
      {
        name,
        email,
        phoneNumber
      }
    );
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error calling POST API:", error);
    throw error; // Re-throwing the error for error handling at a higher level
  }
};


export const userEnquiry = async (name, email, phoneNumber,additionalInfo, currentURL) => {
  try {
    const response = await API.post(
      `/api/util/enquiry`,
      {
        name,
        email,
        phoneNumber,
        additionalInfo,
        currentURL
      }
    );
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error calling POST API:", error);
    throw error; // Re-throwing the error for error handling at a higher level
  }
};


export const authenticateUser = async (username, password) => {
  try {
    const response = await API.post(
      `/api/request/authenticate/user`,
      {
        username,
        password
      }
    );
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error calling POST API:", error);
    throw error; // Re-throwing the error for error handling at a higher level
  }
};

export const getAgentProfile = async (AgentID) => {
  const response = await API.get(`/api/team/${AgentID}`);
  return response.data;
};



export const saveEventLead = async (leadData) => {
  try {
    const response = await API.post('/api/event/register', leadData);
    return response.data; // Assuming the response contains the saved lead data
  } catch (error) {
    console.error("Error saving lead data:", error);
    throw error; // Re-throw the error for further handling if needed
  }
};


export const getGoogleReviews = async (placeId, apiKey) => {
  try {
    const response = await API.get(
      `/api/util/reviews?placeId=${placeId}&apiKey=${apiKey}`
      
    );
    console.log("API response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error calling POST API:", error);
    throw error; // Re-throwing the error for error handling at a higher level
  }
};
