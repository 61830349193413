import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import { saveEventLead } from "../context/endpoints";
import "./eventDetails.css";

const EventDetails = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({}); // State to store validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error for the field being modified
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format regex
    return regex.test(email);
  };

  const handleNext = (e) => {
    e.preventDefault();
    // Validate current step
    if (step === 1 && !formData.name) {
      setErrors({ ...errors, name: "Name is required" });
      return;
    }
    if (step === 2) {
      if (!formData.email) {
        setErrors({ ...errors, email: "Email is required" });
        return;
      } else if (!validateEmail(formData.email)) {
        setErrors({ ...errors, email: "Invalid email format" });
        return;
      }
    }

    setStep(step + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate last step
    if (!formData.phone) {
      setErrors({ ...errors, phone: "Phone number is required" });
      return;
    }

    setIsSubmitting(true); // Disable the button

    try {
      // Sanitize input (basic example)
      const sanitizedData = {
        name: formData.name.trim(),
        email: formData.email.trim(),
        phone: formData.phone.trim(),
      };

      // Call the API to save the lead
      await saveEventLead(sanitizedData);

      // Update state to show submission success
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error saving lead data:", error);
      // Handle error state here if needed
    } finally {
      setIsSubmitting(false); // Enable the button again
    }
  };

  return (
    <>
      <Helmet>
        <title>The Real Cost of Renovation</title>
      </Helmet>
      <Header />
      <div className="event-details">
        {/* Event flyer image */}
        <div className="event-image">
          <img src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/RenovationPanel+Invite+landing+page+use_1080+px+copy+2.jpg" alt="Event Flyer" />
        </div>

        {/* Registration Section */}
        <div className="registration-section">
          <h2>Register for the Event</h2>
          {isSubmitted ? (
            <p className="confirmation-message">
              Thank you! You will receive the invite confirmation by email.
            </p>
          ) : (
            <form onSubmit={handleSubmit} className="registration-form">
              {step === 1 && (
                <>
                  <label>
                    What's your name?
                    <div className="input-container">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      {errors.name && <div className="error-message">{errors.name}</div>} 
                      <button onClick={handleNext} className="next-button">
                        Next <span className="arrow">→</span>
                      </button>
                    </div>
                  </label>
                </>
              )}
              {step === 2 && (
                <>
                  <label>
                    What is your email?
                    <div className="input-container">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <div className="error-message">{errors.email}</div>} {/* Error message below the input */}
                      <button onClick={handleNext} className="next-button">
                        Next <span className="arrow">→</span>
                      </button>
                    </div>
                  </label>
                </>
              )}
              {step === 3 && (
                <>
                  <label>
                    What is your phone number?
                    <div className="input-container">
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      {errors.phone && <div className="error-message">{errors.phone}</div>} 
                      <button type="submit" className="register-button" disabled={isSubmitting}>
                        {isSubmitting ? 'Registering...' : 'Register'}
                      </button>
                    </div>
                  </label>
                </>
              )}
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
